import { Typography } from '@mui/material';

type FileNameFormatProps = {
  filenameExample: string;
  filenameFormat: string;
};

const FileNameFormat: React.FC<FileNameFormatProps> = ({
  filenameExample,
  filenameFormat,
}: FileNameFormatProps) => (
  <Typography variant='subtitle2' sx={{ textAlign: 'center', fontSize: '13px', fontStyle: 'bold' }}>
    <p>
      <b>
        File name format:
        <br />
        {filenameFormat}
      </b>
      <br />
      (
      {filenameExample}
      )
    </p>
  </Typography>
);

export default FileNameFormat;
