import useAxiosInstance from './useAxiosInstance';

interface RoleRateRepositoryProps {
  baseURL: string;
}

const useFeatureFlagRepository = ({ baseURL }: RoleRateRepositoryProps) => {
  const { axiosInstance } = useAxiosInstance({ baseURL });

  // eslint-disable-next-line max-len
  const getFeatureFlagsFromBackend = async (url: string) => {
    const { data } = await axiosInstance.get(url);
    return data;
  };

  return { getFeatureFlagsFromBackend };
};

export default useFeatureFlagRepository;
