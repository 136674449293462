import React from 'react';
import { NumberField, NumberFieldProps } from 'react-admin';

type CustomCurrencyFieldProps = Omit<NumberFieldProps, 'options' | 'emptyText' | 'locales'> & {
  currency?: string;
  emptyText?: string;
  locales?: string | string[];
};

const CustomCurrencyField: React.FC<CustomCurrencyFieldProps> = ({
  currency,
  emptyText,
  locales,
  ...props
}) => (
  <NumberField
    options={{ style: 'currency', currency }}
    emptyText={emptyText}
    locales={locales}
    {...props}
  />
);

CustomCurrencyField.defaultProps = {
  currency: 'USD',
  emptyText: 'N/A',
  locales: 'en-US',
};

export default CustomCurrencyField;
