import { createContext } from 'react';

type FeatureFlag = {
  toggles: Record<string, boolean>;
};

const FeatureFlagContext = createContext<FeatureFlag>({
  toggles: {},
});

export default FeatureFlagContext;
