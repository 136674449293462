import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone, FileRejection } from 'react-dropzone';
import { AppColors } from '../../../../AppTheme';
import useFileNameValidation from '../../../../hooks/fileNameValidation/useFileNameValidation';
import { FileType } from '../../../../utils/types/manualReport';

type ManualReportDropzoneProps = {
  title: string;
  required: boolean;
  fileType: FileType;
  fileNamePattern: RegExp;
  maxFiles: number;
  error: React.Dispatch<React.SetStateAction<string>>;
  file: React.Dispatch<React.SetStateAction<File | undefined>>;
  selectedDate: Date;
  filenameFormat: JSX.Element;
};

const ManualReportDropzone: React.FC<ManualReportDropzoneProps> = ({
  title,
  required,
  fileType,
  fileNamePattern,
  selectedDate,
  maxFiles,
  error,
  file,
  filenameFormat,
}) => {
  const [hover, setHover] = useState(false);
  const { handleValidation } = useFileNameValidation({
    fileType,
    fileNamePattern,
    selectedDate,
  });

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
    },
    validator: handleValidation,
    onDropRejected: (fileRejections: FileRejection[]) => {
      if (fileRejections.length > maxFiles) {
        error(`Max files to select is ${maxFiles}`);
      } else {
        fileRejections.forEach((fileRejection) => {
          fileRejection.errors.forEach((e) => error(`${e.message}`));
        });
      }
      file(undefined);
    },
    onDropAccepted: (acceptedFiles: File[]) => {
      error('');
      file(acceptedFiles[0]);
    },
  });

  return (
    <Box>
      {hover ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'absolute',
            marginX: '2.5%',
            transform: 'translateY(-50px)',
          }}
        >
          <Typography
            variant='subtitle2'
            sx={{
              textAlign: 'center',
              fontSize: '13px',
              backgroundColor: '#717171',
              borderRadius: '5px',
              width: '170px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              height: '45px',
              color: 'white',
            }}
          >
            <p>
              Drag and drop or browse
              <br />
              to choose a file
            </p>
          </Typography>
        </Box>
      ) : null}
      <Box
        {...getRootProps({ className: 'dropzone' })}
        sx={{
          height: '200px',
          minWidth: '200px',
          width: '260px',
          border: 'dashed 2px rgba(1,1,1,0.2)',
          backgroundColor: 'hsl(357deg 22% 97%)',
          borderRadius: '15px',
          boxShadow: '3px 3px 4px hsl(0deg 2% 74% / 20%)',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: AppColors.hover,
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <input {...getInputProps()} data-testid={`input-${title}`} />
        <Box
          sx={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <CloudUploadIcon sx={{ transform: 'scale(3)', marginBottom: '20px' }} color='primary' />
          <Typography variant='subtitle1'>
            {title}
            {required && <span style={{ color: 'red' }}>&#42;</span>}
          </Typography>
          {filenameFormat}
        </Box>
      </Box>
    </Box>
  );
};

export default ManualReportDropzone;
