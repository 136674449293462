import React, { useState } from 'react';
import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import ReportPreviewManager from '../../../../../business_logic/managers/ReportPreviewManager';
import useReportPreviewTables from '../../../../../hooks/useReportPreviewTables';

const customLocaleText = {
  noRowsLabel: 'There are no Warnings',
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'column-header',
    width: 300,
    disableColumnMenu: false,
    hideable: false,
  },
  {
    field: 'client',
    headerName: 'Client Name',
    headerClassName: 'column-header',
    width: 250,
    disableColumnMenu: true,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    headerClassName: 'column-header',
    width: 250,
    disableColumnMenu: true,
  },
  {
    field: 'description',
    headerName: 'Description',
    headerClassName: 'column-header',
    width: 500,
    disableColumnMenu: true,
  },
];

const reportPreviewManager = ReportPreviewManager.getInstance();

const ReportPreviewWarnings: React.FC = () => {
  const { datagridInitState, warningsColumnVisibility: initVisibility } = useReportPreviewTables();

  const [rows] = useState(reportPreviewManager.getAllWarnings());

  const [visibility, setVisibility] = React.useState<GridColumnVisibilityModel>(initVisibility);

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      columnVisibilityModel={visibility}
      onColumnVisibilityModelChange={(newModel) => setVisibility(newModel)}
      initialState={datagridInitState}
      pageSizeOptions={[25, 50, 100]}
      localeText={customLocaleText}
      disableRowSelectionOnClick
      disableColumnFilter
      disableVirtualization
    />
  );
};
export default ReportPreviewWarnings;
