import React from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import WarningIcon from '@mui/icons-material/Warning';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from './TabPanel';
import ReportPreviewManager from '../../../business_logic/managers/ReportPreviewManager';
import { AppColors } from '../../../AppTheme';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

type CustomTabsProps = {
  tabs: {
    id: string;
    label: string;
    content: React.ReactNode;
  }[];
  tabHeaderStyle?: TabsProps['sx'];
  width?: string | number;
};

const reportPreviewManager = ReportPreviewManager.getInstance();

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, width, tabHeaderStyle }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const warnings = reportPreviewManager.getAllWarnings();

  return (
    <Box sx={{ width }}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChange} aria-label='Tabs'>
          {tabs.map(({ id, label }, index) => {
            if (label.toLowerCase() === 'warnings' && warnings.length > 0) {
              return (
                <Tab
                  sx={tabHeaderStyle}
                  icon={<WarningIcon style={{ color: AppColors.main }} />}
                  iconPosition='end'
                  key={id}
                  label={label}
                  {...a11yProps(index)}
                />
              );
            }
            return <Tab sx={tabHeaderStyle} key={id} label={label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      {tabs.map(({ id, content }, index) => (
        <TabPanel key={id} value={activeTab} index={index}>
          {content}
        </TabPanel>
      ))}
    </Box>
  );
};

CustomTabs.defaultProps = {
  width: '100%',
  tabHeaderStyle: undefined,
};

export default CustomTabs;
