import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

type ActionColumnProps = {
  onClickAction: () => void;
  ariaLabel: string;
  icon: React.ElementType;
} & IconButtonProps;

const ActionColumn: React.FC<ActionColumnProps> = ({
  onClickAction,
  ariaLabel,
  icon: Icon,
  ...props
}) => (
  <IconButton sx={{ minWidth: '42px' }} onClick={onClickAction} aria-label={ariaLabel} {...props}>
    <Icon />
  </IconButton>
);

export default ActionColumn;
