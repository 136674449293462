import React from 'react';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DateMethods from '../../../../../utils/dateMethods';
import usePtoDetailsDate from '../../../../../hooks/usePtoDetailsDate';

const buttonAddStyle = {
  height: '1.5rem',
  minWidth: '2.3rem',
  width: '2rem',
  marginTop: '.2rem',
};

type RangeType = {
  min: string;
  max: string;
};
interface DateNewProps {
  startDate: string;
  endDate: string;
  handleCreateDate: (newDate: string) => void;
  range: RangeType;
}

interface ButtonFieldProps
  extends UseDateFieldProps<Date>,
  BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
  // eslint-disable-next-line react/require-default-props
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

type FieldProps<T> = {
  setOpen: (open: boolean) => void;
  value?: T;
};

const ButtonCalendar: React.FC = (props: ButtonFieldProps) => {
  const { setOpen, InputProps: { ref } = {} } = props;

  return (
    <Button
      variant='contained'
      sx={buttonAddStyle}
      ref={ref}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <AddCircleOutlineIcon />
    </Button>
  );
};

const DateNew: React.FC<DateNewProps> = ({
  startDate,
  endDate,
  handleCreateDate,
  range,
}: DateNewProps) => {
  const minAllowDate = startDate.localeCompare(range.min) < 0
    ? new Date(DateMethods.addZeroTime(range.min))
    : new Date(DateMethods.addZeroTime(startDate));

  const maxAllowDate = endDate.length > 1
    ? new Date(DateMethods.addZeroTime(endDate))
    : new Date(DateMethods.addZeroTime(range.max));

  const [openDatePicker, setOpenDatePicker] = React.useState<boolean>(false);
  const { isWeekend } = usePtoDetailsDate();

  const handleChange = (newDate: Date | null) => {
    if (newDate && !Number.isNaN(newDate.getTime())) {
      handleCreateDate(DateMethods.datetoString(newDate));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        slots={{ field: ButtonCalendar }}
        slotProps={{ field: { setOpen: setOpenDatePicker } as FieldProps<Date> }}
        value={null}
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        onOpen={() => setOpenDatePicker(true)}
        onChange={(newDate) => handleChange(newDate)}
        minDate={minAllowDate}
        maxDate={maxAllowDate}
        shouldDisableDate={isWeekend}
      />
    </LocalizationProvider>
  );
};
export default DateNew;
