const pad2digits = (digit: number) => digit?.toString()?.padStart(2, '0');

const formatDate = (dateToFormat: Date) => {
  const day = pad2digits(dateToFormat.getDate());
  const month = pad2digits(dateToFormat.getMonth() + 1);
  const year = pad2digits(dateToFormat.getFullYear());
  return `${day}-${month}-${year}`;
};

export default formatDate;
