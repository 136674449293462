import { useCallback, useState } from 'react';
import { ValidationError, object as yupObject } from 'yup';
import {
  ErrorFields, TargetType, UseForm, YupSchemaObject,
} from '../utils/types';

function serializeYupErrors<T>(err: ValidationError) {
  return err.inner.reduce((acc: ErrorFields<T>, val: ValidationError) => {
    const fieldName = val.path as keyof T | undefined;
    if (fieldName) acc[fieldName] = val.message;

    return acc;
  }, {});
}

const useForm = <FormType>(
  initialState: FormType,
  validationSchema?: YupSchemaObject<FormType>,
): UseForm<FormType> => {
  const [formValues, setFormValues] = useState(initialState);
  const [errors, setErrors] = useState<ErrorFields<FormType>>({});

  const validateForm = useCallback(
    async (context?: FormType) => {
      const values = context || formValues;
      if (validationSchema) {
        const schema = yupObject(validationSchema);
        return schema.validate(values, { abortEarly: false });
      }
      // If no validationSchema return values
      return values;
    },
    [validationSchema, formValues],
  );

  const validateField = useCallback(
    async (field: Record<string, unknown>) => {
      if (validationSchema) {
        const schema = yupObject(validationSchema);
        await schema.validateAt(
          Object.keys(field)[0],
          { ...formValues, ...field },
          { abortEarly: false },
        );
      }
    },
    [validationSchema, formValues],
  );

  const reset = () => {
    setErrors({});
    setFormValues(initialState);
  };

  const handleInputChange = (
    { target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | TargetType<FormType>,
    validate = true,
  ) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [target.name]: target.value,
    }));
    if (validate && validationSchema) {
      validateField({ [target.name]: target.value })
        .then(() => {
          const fieldKey = target.name as keyof FormType;
          if (errors[fieldKey]) {
            setErrors((previousErrors) => {
              const newErrors = { ...previousErrors };
              delete newErrors[fieldKey];
              return newErrors;
            });
          }
        })
        .catch((error) => {
          if (error instanceof ValidationError) {
            const validationErrors = serializeYupErrors<FormType>(error);
            setErrors((prevErrors) => ({
              ...prevErrors,
              ...validationErrors,
            }));
          }
        });
    }
  };

  return {
    formValues,
    handleInputChange,
    reset,
    setFormValues,
    errors,
    setErrors,
    validateForm,
  };
};

export default useForm;
