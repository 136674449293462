import { useState } from 'react';
import {
  Stack, Typography, Button, Box,
} from '@mui/material';
import { useSidebarState } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import ManualReportDropzone from '../../molecules/Dropzone/ManualReportDropzone';

import useDownloadFile from '../../../hooks/useDownloadFile';
import FileList from './FileList';
import DatePicker from '../../molecules/DatePicker';
import formatDate from '../../../business_logic/utils/formatDate';
import Config from '../../../utils/config';
import useFileNameDateValidator from '../../../hooks/fileNameValidation/useFileNameDateValidation';
import FileNameFormat from '../../molecules/FileNameFormat';
import useReportPreview from '../../../hooks/useReportPreview';
import ReportPreviewParser from '../../../utils/parsers/reportPreviewParser';
import ReportPreviewManager from '../../../business_logic/managers/ReportPreviewManager';

const centerPageStyle = {
  paddingTop: '64px',
  justifyContent: 'center',
  alignItems: 'center',
  height: '-webkit-fill-available',
  width: '100%',
};

const ManualReport = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [error, setError] = useState('');
  const [loadingReportPreview, setLoadingReportPreview] = useState(false);
  const [notionFile, setNotionFile] = useState<File>();
  const [bambooFile, setBambooFile] = useState<File>();
  const [reportInHoursFile, setReportInHoursFile] = useState<File>();
  const { getManualReportFile, loading: loadingManualReportFile } = useDownloadFile();
  const { getReportPreview } = useReportPreview({ baseURL: Config.backendUrl });
  const { hasValidDate, ERROR_MESSAGE: DATE_ERROR_MESSAGE } = useFileNameDateValidator();
  const navigate = useNavigate();
  const [, setOpen] = useSidebarState();
  const { numberOfFiles } = Config;

  const validateFileDate = (file?: File) =>
    new Promise<void>((resolve, reject) => {
      if (file && !hasValidDate(file, date)) {
        reject(new Error(DATE_ERROR_MESSAGE));
      } else {
        resolve();
      }
    });

  const [month, year] = formatDate(date).split('-').slice(1);

  const downloadReport = async () => {
    const url = `/manual-report/${year}/${Number(month)}`;
    const fileName = `report_${year}_${month}.xlsx`;

    if (notionFile && bambooFile) {
      try {
        await Promise.all([
          validateFileDate(bambooFile),
          validateFileDate(notionFile),
          validateFileDate(reportInHoursFile),
        ]);
        await getManualReportFile(url, fileName, notionFile, bambooFile, reportInHoursFile);
      } catch (err) {
        let message = 'Unknown Error';
        if (err instanceof Error) message = err.message;
        setError(message);
      }
    } else {
      setError('Please, first select the files for downloading the report.');
    }
  };

  const previewReport = async () => {
    const url = `/manual-report-preview/${year}/${Number(month)}`;
    if (notionFile && bambooFile) {
      try {
        setLoadingReportPreview(true);
        await Promise.all([
          validateFileDate(bambooFile),
          validateFileDate(notionFile),
          validateFileDate(reportInHoursFile),
        ]);
        const formData = new FormData();
        formData.append('notion_file', notionFile);
        formData.append('bamboo_file', bambooFile);
        if (reportInHoursFile) {
          formData.append('report_in_hours_file', reportInHoursFile);
        }
        const reportPreviewRaw = await getReportPreview(url, formData);
        const reportPreview = ReportPreviewParser.parseToReportPreviewFormatted(
          reportPreviewRaw,
          date,
        );
        const reportPreviewManager = ReportPreviewManager.getInstance();
        reportPreviewManager.resetReport();
        reportPreviewManager.setReport(reportPreview);
        setOpen(false);
        navigate(`/reportPreview/${date}`);
      } catch (err) {
        let message = 'Unknown Error';
        if (err instanceof Error) message = err.message;
        setError(message);
      }
    } else {
      setError('Please, first select the files to generate report preview.');
    }
  };

  const onChange = (newDate: Date | null) => {
    if (newDate) {
      setDate(newDate);
      setBambooFile(undefined);
      setNotionFile(undefined);
      setReportInHoursFile(undefined);
    }
  };

  return (
    <Stack sx={centerPageStyle} spacing={4}>
      <Typography variant='h3'>Manual Report</Typography>
      <Stack
        justifyContent='center'
        alignItems='center'
        spacing={4}
        sx={{
          maxWidth: '600px',
          width: '60%',
        }}
      >
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          sx={{ paddingBottom: '25px' }}
        >
          <Typography variant='subtitle1'>Select Date:</Typography>
          <DatePicker date={date} handleChange={onChange} />
        </Stack>
        <Stack direction='row' spacing={6}>
          <Stack spacing={2}>
            <ManualReportDropzone
              title='Notion file'
              required
              fileType='Notion'
              fileNamePattern={/^[A-Za-z]+_\d{4}-[A-Za-z]{3}\.(xlsx|xls)$/}
              selectedDate={date}
              maxFiles={numberOfFiles}
              error={setError}
              file={setNotionFile}
              filenameFormat={(
                <FileNameFormat
                  filenameFormat='Name_Year-Month'
                  filenameExample='e.g. Notion_2023-Jan.xlsx'
                />
              )}
            />
            {notionFile ? <FileList files={[notionFile]} /> : null}
          </Stack>
          <Stack spacing={2}>
            <ManualReportDropzone
              title='Bamboo file'
              required
              fileType='Bamboo'
              fileNamePattern={/^[A-Za-z]+_\d{4}-[A-Za-z]{3}\.(xlsx|xls)$/}
              selectedDate={date}
              maxFiles={numberOfFiles}
              error={setError}
              file={setBambooFile}
              filenameFormat={(
                <FileNameFormat
                  filenameFormat='Name_Year-Month'
                  filenameExample='e.g. Bamboo_2023-Jan.xlsx'
                />
              )}
            />
            {bambooFile ? <FileList files={[bambooFile]} /> : null}
          </Stack>
          <Stack spacing={2}>
            <ManualReportDropzone
              title='Report in hours file'
              required={false}
              fileType='Hourly'
              fileNamePattern={/^Hourly_[A-Za-z'&]+_\d{4}-[A-Za-z]{3}\.(xlsx|xls)$/}
              selectedDate={date}
              maxFiles={numberOfFiles}
              error={setError}
              file={setReportInHoursFile}
              filenameFormat={(
                <FileNameFormat
                  filenameFormat='Hourly_Name_Year-Month'
                  filenameExample='e.g. Hourly_Report_2023-Jan.xlsx'
                />
              )}
            />
            {reportInHoursFile ? <FileList files={[reportInHoursFile]} /> : null}
          </Stack>
        </Stack>
        <Box>
          <Button
            disabled={loadingManualReportFile || loadingReportPreview}
            variant='contained'
            sx={{ width: '250px' }}
            onClick={downloadReport}
          >
            {loadingManualReportFile ? <CircularProgress color='inherit' size={24} /> : 'DOWNLOAD'}
          </Button>
          <Button
            disabled={loadingManualReportFile || loadingReportPreview}
            variant='contained'
            sx={{ width: '250px', ml: '10px' }}
            onClick={previewReport}
          >
            {loadingReportPreview ? (
              <CircularProgress color='inherit' size={24} />
            ) : (
              'REPORT PREVIEW'
            )}
          </Button>
        </Box>
      </Stack>
      <Snackbar open={error !== ''} autoHideDuration={4000} onClose={() => setError('')}>
        <Alert variant='filled' severity='error'>
          {error}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ManualReport;
