const usePtoDetailsDate = () => {
  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  return {
    isWeekend,
  };
};

export default usePtoDetailsDate;
