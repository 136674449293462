import { Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React from 'react';
import { AppColors } from '../../../AppTheme';

type PropsAvatar = {
  imgUrl: string | null | undefined;
};

const styleAvatar = {
  backgroundColor: AppColors.white,
  color: AppColors.main,
};

const CustomAvatar: React.FC<PropsAvatar> = ({ imgUrl }) => (imgUrl ? (
  <Avatar sx={styleAvatar} src={imgUrl} />
) : (
  // Generic account icon
  <Avatar sx={styleAvatar}>
    <AccountCircleIcon />
  </Avatar>
));

export default CustomAvatar;
