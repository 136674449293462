import React, { useEffect, useState } from 'react';
import { GridEditCellProps } from '@mui/x-data-grid';
import { Box, Grid } from '@mui/material';
import { PtoFormatted } from '../../../../../business_logic/models/reportPreview';
import ReportPreviewManager from '../../../../../business_logic/managers/ReportPreviewManager';
import useDateValidations from '../../../../../hooks/useDateValidations';
import useReportPreviewTables from '../../../../../hooks/useReportPreviewTables';
import DateMethods from '../../../../../utils/dateMethods';
import DateError from './DateError';
import DateList from './DateList';
import DateNew from './DateNew';

const boxStyle = {
  display: 'flex',
  justifyContent: 'left',
  gap: '.5rem',
};

interface DatesHandlerProps {
  params: GridEditCellProps;
  updateRow: (newRow: PtoFormatted) => void;
  updateRowHeight: (newHeight: number) => void;
}

const reportPreviewManager = ReportPreviewManager.getInstance();

const DateHandler: React.FC<DatesHandlerProps> = ({
  params,
  updateRow,
  updateRowHeight,
}: DatesHandlerProps) => {
  const { row, field } = params;
  const {
    workingDays,
    ptoPlusUto,
    ptoPlusUtoDates,
    ptoOther,
    ptoOtherDates,
    date: holidays,
    startDate,
    endDate,
  } = row;

  const [firstDayOfMonth, lastDayOfMonth] = DateMethods.getFirstAndLastDayOfMonth(
    reportPreviewManager.getDate(),
  );

  const [initFromDates, initToDates] = field === 'ptoPlusUtoDates'
    ? [ptoPlusUtoDates.from, ptoPlusUtoDates.to]
    : [ptoOtherDates.from, ptoOtherDates.to];

  const [otherFromDates, otherToDates] = field === 'ptoPlusUtoDates'
    ? [ptoOtherDates.from, ptoOtherDates.to]
    : [ptoPlusUtoDates.from, ptoPlusUtoDates.to];

  const [fromDates, setFromDates] = useState<string[]>(initFromDates);
  const [toDates, setToDates] = useState<string[]>(initToDates);

  const [errorText, setErrorText] = useState('');
  const [errorVisible, setErrorVisible] = useState(false);

  const { isDateAvailable, validateFromDate, validateToDate } = useDateValidations(
    fromDates,
    toDates,
    otherFromDates,
    otherToDates,
  );

  const { getRowHeight } = useReportPreviewTables();

  const allowedDateRange = {
    min: firstDayOfMonth,
    max: lastDayOfMonth,
  };

  useEffect(() => {
    const initHeight = getRowHeight(initFromDates, otherFromDates);
    updateRowHeight(initHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showError = (message: string) => {
    setErrorText(message);
    setErrorVisible(true);

    setTimeout(() => {
      setErrorVisible(false);
    }, 2000);
  };

  const handleUpdateRow = (
    updatedFromDates: string[],
    updatedToDates: string[],
    daysOff: number,
  ) => {
    const newRow = { ...row };

    if (field === 'ptoPlusUtoDates') {
      const total = workingDays - ptoOther - daysOff;
      newRow.ptoPlusUto = daysOff <= workingDays ? daysOff : workingDays;
      newRow.ptoPlusUtoDates = {
        from: updatedFromDates,
        to: updatedToDates,
      };
      newRow.totalBilledDays = total > 0 ? total : 0;
    } else {
      const total = workingDays - ptoPlusUto - daysOff;
      newRow.ptoOther = daysOff <= workingDays ? daysOff : workingDays;
      newRow.ptoOtherDates = {
        from: updatedFromDates,
        to: updatedToDates,
      };
      newRow.totalBilledDays = total > 0 ? total : 0;
    }
    updateRow(newRow);
    updateRowHeight(getRowHeight(updatedFromDates, otherFromDates));
  };

  const handleFromDateChange = (newDate: string, index: number) => {
    if (validateFromDate(newDate, index, showError)) {
      const updatedFromDates = fromDates;
      updatedFromDates[index] = newDate;
      setFromDates(updatedFromDates);

      const daysOff = DateMethods.getDaysOff(updatedFromDates, toDates, holidays);
      handleUpdateRow(updatedFromDates, toDates, daysOff);
    }
  };

  const handleToDateChange = (newDate: string, index: number) => {
    if (validateToDate(newDate, index, showError)) {
      const updatedToDates = toDates;
      updatedToDates[index] = newDate;
      setToDates(updatedToDates);

      const daysOff = DateMethods.getDaysOff(fromDates, updatedToDates, holidays);
      handleUpdateRow(fromDates, updatedToDates, daysOff);
    }
  };

  const handleDeleteDate = (index: number) => {
    const updatedFromDates = fromDates;
    const updatedToDates = toDates;
    updatedFromDates.splice(index, 1);
    updatedToDates.splice(index, 1);
    setFromDates(updatedFromDates);
    setToDates(updatedToDates);

    const daysOff = DateMethods.getDaysOff(updatedFromDates, updatedToDates, holidays);
    handleUpdateRow(updatedFromDates, updatedToDates, daysOff);
  };

  const handleCreateDate = (newDate: string) => {
    if (isDateAvailable(newDate, showError)) {
      const updatedFromDates = [...fromDates, newDate];
      const updatedToDates = [...toDates, newDate];
      /* eslint-disable */
      const [updatedSortedFromDates, updatedSortedToDates] =
        DateMethods.orderNewDatesAscendingOrder(updatedFromDates, updatedToDates);
      /* eslint-enable */
      setFromDates(updatedSortedFromDates);
      setToDates(updatedSortedToDates);

      const daysOff = DateMethods.getDaysOff(updatedFromDates, updatedToDates, holidays);
      handleUpdateRow(updatedFromDates, updatedToDates, daysOff);
    }
  };

  return (
    <Grid container direction='column' sx={{ padding: '.4rem' }}>
      <Grid item xs={8}>
        <DateList
          fromDates={fromDates}
          toDates={toDates}
          startDate={startDate}
          endDate={endDate}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          handleDeleteDate={handleDeleteDate}
          range={allowedDateRange}
        />
      </Grid>

      <Grid item xs={4}>
        <Box sx={boxStyle}>
          <DateNew
            startDate={startDate}
            endDate={endDate}
            handleCreateDate={handleCreateDate}
            range={allowedDateRange}
          />
          <DateError error={errorText} visible={errorVisible} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateHandler;
