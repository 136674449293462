import { Stack, Typography } from '@mui/material';

const RolePermissions = () => (
  <Stack
    direction='column'
    justifyContent='center'
    alignItems='center'
    spacing={2}
    padding={10}
    sx={{
      height: '-webkit-fill-available',
    }}
  >
    <Typography variant='h3' color='primary'>
      ACCESS DENIED!
    </Typography>
    <Typography variant='subtitle1' align='center'>
      You do not have access to this resource, if you need it please contact the
      Billing App technical team.
    </Typography>
  </Stack>
);

export default RolePermissions;
