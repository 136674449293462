import { Stack } from '@mui/material';
import RatesManagerTable from '../../molecules/Tables/RatesManager';
import PageTitle from '../../molecules/PageTitle';

const containerMainStyles = {
  justifyContent: 'top',
  alignItems: 'center',
  marginTop: '64px',
  height: '-webkit-fill-available',
};

const RatesManager = () => (
  <Stack sx={containerMainStyles}>
    <Stack spacing={2}>
      <PageTitle title='Manage Rates' subtitle='List of Rates' />
      <RatesManagerTable />
    </Stack>
  </Stack>
);

export default RatesManager;
