import {
  RoleRateFilter,
  RoleRateStatistics,
  RoleRateItemReq,
} from '../business_logic/models/roleRateEntry';
import useAxiosInstance from './useAxiosInstance';
import { UpdateRoleDTO } from '../utils/types';

interface RoleRateRepositoryProps {
  baseURL: string;
}

const useRoleRatesRepository = ({ baseURL }: RoleRateRepositoryProps) => {
  const { axiosInstance } = useAxiosInstance({ baseURL });

  // eslint-disable-next-line max-len
  const getRoles = async (
    url: string,
    filters: RoleRateFilter,
    withStatistics: RoleRateStatistics,
  ) => {
    const { data } = await axiosInstance.post(url, {
      filters,
      ...{ with_statistics: withStatistics },
    });
    return data;
  };

  const updateRole = (url: string, roleDataToUpdate: UpdateRoleDTO) =>
    axiosInstance.put(url, roleDataToUpdate);

  const addRoleRate = async (roleRate: RoleRateItemReq, url: string) => {
    const { data } = await axiosInstance.post(url, roleRate);
    return data;
  };

  return { getRoles, updateRole, addRoleRate };
};

export default useRoleRatesRepository;
