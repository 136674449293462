import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import FeatureFlagContext from '../../context/featureFlagContext';

type FetchFeatureFlagsType = () => Promise<Record<string, boolean>>;

interface FeatureFlagProviderProps {
  fetchFeatureFlags: FetchFeatureFlagsType;
  children: React.ReactNode;
}

const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  fetchFeatureFlags,
  children,
}: FeatureFlagProviderProps) => {
  const shouldFetchRef = useRef(true);
  const [togglesState, setToggles] = useState({});

  const getToggles = async () => {
    const result = await fetchFeatureFlags();
    setToggles(result);
  };

  useEffect(() => {
    if (shouldFetchRef.current) {
      getToggles();
      shouldFetchRef.current = false;
    }
  });

  const toggles = useMemo(() => ({ toggles: togglesState }), [togglesState]);

  return <FeatureFlagContext.Provider value={toggles}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagProvider;
