import React from 'react';
import { useRecordContext, PublicFieldProps, RaRecord } from 'react-admin';
import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import ContentCreate from '@mui/icons-material/Create';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionColumn from './ActionColumn';

const containerActionsStyles = {
  display: 'flex',
  gap: '10px',
  textAlign: 'center',
  justifyContent: 'center',
};
const iconsByActionType = {
  editRackRateValues: ContentCreate,
  delete: DeleteIcon,
  history: HistoryIcon,
  editDefault: () => (
    <Chip color='primary' sx={{ width: '6rem' }} label='Default' variant='outlined' />
  ),
};

const getIconByActionType = (actionType: ActionType, isDefault: boolean) => {
  if (actionType === 'editDefault' && isDefault) {
    return () => (
      <Chip
        className='isDefaultActive'
        role='chipEditDefault'
        color='primary'
        sx={{ width: '6rem', backgroundColor: 'rgba(218, 91, 97, 0.29)' }}
        label='Default'
        variant='outlined'
      />
    );
  }
  return iconsByActionType[actionType];
};

type ActionType = 'editRackRateValues' | 'delete' | 'history' | 'editDefault';

type RowActionsProps<RecordType> = {
  actions: {
    actionType: ActionType;
    onClick: (record: RecordType) => void;
    hidden?: boolean;
  }[];
} & PublicFieldProps;

const RowActions = <T extends RaRecord>({ actions }: RowActionsProps<T>) => {
  const record = useRecordContext<T>();
  return (
    <Box sx={containerActionsStyles}>
      {actions
        .filter((action) => !action.hidden)
        .map(({ actionType, onClick }) => (
          <ActionColumn
            key={`${actionType}-${record.id}`}
            color='primary'
            ariaLabel={actionType}
            name={actionType}
            icon={getIconByActionType(actionType, record.isDefault)}
            onClickAction={() => onClick(record)}
          />
        ))}
    </Box>
  );
};

export default RowActions;
