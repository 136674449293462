import { FileType } from '../../utils/types/manualReport';
import useFileNameDateValidator from './useFileNameDateValidation';

interface FileNameValidationProps {
  fileType: FileType;
  selectedDate: Date;
  fileNamePattern: RegExp;
}

const useFileNameValidation = ({
  fileType,
  selectedDate,
  fileNamePattern,
}: FileNameValidationProps) => {
  const getTypeFromFileName = (fileName: string): string => fileName.split('_')[0];

  const fileTypeMatchesDropzoneType = (fileNameType: string): boolean => fileNameType === fileType;
  const { hasValidDate, ERROR_MESSAGE: DATE_ERROR_MESSAGE } = useFileNameDateValidator();

  const handleValidation = (file: File) => {
    const fileNameMatchesPattern = fileNamePattern.test(file.name);
    if (!fileNameMatchesPattern) {
      return { message: 'File name does not follow the recommended pattern', code: '' };
    }
    const reportType = getTypeFromFileName(file.name);
    if (!fileTypeMatchesDropzoneType(reportType)) {
      return { message: 'File does not correspond to this input', code: '' };
    }

    if (!hasValidDate(file, selectedDate)) {
      return {
        message: DATE_ERROR_MESSAGE,
        code: '',
      };
    }
    return null;
  };

  return { handleValidation };
};

export default useFileNameValidation;
