import Config from '../utils/config';
import ALL_ROLES from '../utils/constants';

const AuthConfig = {
  storageUserKey: 'billing-ioet-user',
  loginPath: 'login',
  allowedRoles: ALL_ROLES,
  appName: Config.appName,
  authServiceLoginURL: `${Config.authURL}/authn/login/${Config.appName}`,
};

export default AuthConfig;
