import { UserMenu, Logout, useGetIdentity } from 'react-admin';
import LogoutIcon from '@mui/icons-material/Logout';
import { CircularProgress, Box } from '@mui/material';
import CustomAvatar from '../Avatar';

const containerAvatarStyles = {
  display: 'flex',
  width: '14rem',
  marginLeft: '0.5rem',
};

const CustomUserMenu = () => {
  const { identity, isLoading } = useGetIdentity();
  return (
    <Box role='avatar' sx={containerAvatarStyles}>
      {isLoading ? (
        <CircularProgress color='inherit' size={24} />
      ) : (
        <UserMenu
          icon={
            <CustomAvatar imgUrl={identity?.picture ? identity?.picture : ''} />
          }
        >
          <Logout icon={<LogoutIcon />} />
        </UserMenu>
      )}
    </Box>
  );
};

export default CustomUserMenu;
