const DateMethods = {
  hasValidDateFormat(dateString: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  },

  toStandardFormat(date: string): string {
    if (!date) {
      return '';
    }
    const [month, day, year] = date.split('-');
    return `${year}-${month}-${day}`;
  },

  formatHolidaysArray(dates: string): string[] {
    const formattedDates: string[] = [];
    if (dates === '') {
      return formattedDates;
    }
    const dateStrings = dates.trim().split('\n');
    dateStrings.forEach((dateString) => {
      formattedDates.push(dateString);
    });
    return formattedDates;
  },

  formatDatesArray(dates: string): string[] {
    const formattedDates: string[] = [];
    if (dates === '') {
      return formattedDates;
    }
    const dateStrings = dates.trim().split('\n');

    dateStrings.forEach((dateString) => {
      formattedDates.push(DateMethods.toStandardFormat(dateString));
    });

    return formattedDates;
  },

  addZeroTime(date: string): string {
    return `${date}T00:00:00`;
  },

  datetoString(date: Date): string {
    return date.toISOString().split('T')[0];
  },

  getFirstAndLastDayOfMonth(dateStr: string): string[] {
    const date = new Date(DateMethods.addZeroTime(dateStr));
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return [DateMethods.datetoString(firstDayOfMonth), DateMethods.datetoString(lastDayOfMonth)];
  },

  getDatesInRange(startDate: string, endDate: string, holidays: string[]): string[] {
    const dateList = [];
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const date = DateMethods.datetoString(currentDate);
      const day = currentDate.getDay();
      const isWeekend = day === 6 || day === 0;
      const isHoliday = holidays.includes(date);
      if (!isWeekend && !isHoliday) {
        dateList.push(date);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateList;
  },

  getDaysOff(startDates: string[], endDates: string[], holidays: string[]): number {
    let daysOff = 0;
    for (let i = 0; i < startDates.length; i += 1) {
      daysOff += DateMethods.getDatesInRange(
        DateMethods.addZeroTime(startDates[i]),
        DateMethods.addZeroTime(endDates[i]),
        holidays,
      ).length;
    }
    return daysOff;
  },

  notOverlap(range1Start: Date, range1End: Date, range2Start: Date, range2End: Date): boolean {
    return range1End < range2Start || range2End < range1Start;
  },

  orderNewDatesAscendingOrder(from: string[], to: string[]): [string[], string[]] {
    const sortedFrom: string[] = [];
    const sortedTo: string[] = [];

    const indexedDates = from.map((date, index) => ({ date, index }));

    indexedDates.sort((a, b) => a.date.localeCompare(b.date));
    /* eslint-disable */
    indexedDates.map((data, index) => {
      sortedFrom[index] = data.date;
      sortedTo[index] = to[data.index];
    });
    /* eslint-enable */

    return [sortedFrom, sortedTo];
  },
};

export default DateMethods;
