import axios from 'axios';
import AuthConfig from './config';
import Config from '../utils/config';

const BACKEND_AUTH_PATH = '/auth';
const AUTH_SERVICE_PATH = '/authn';

const backend = axios.create({
  baseURL: Config.backendUrl,
  withCredentials: true,
});

const authBackend = axios.create({
  baseURL: Config.authURL,
  withCredentials: true,
});

const authService = {
  getLoggedUser: async () => backend.get(`${BACKEND_AUTH_PATH}/get-permissions`),
  getUserPermissions: async () => {
    const { appName } = AuthConfig;
    const { data: currentUser } = await authService.getLoggedUser();
    const { roles, feature_toggles: featureToggles } = currentUser;
    return {
      roles: roles?.[appName] || [],
      featureToggles: featureToggles?.[appName] || [],
    };
  },
  logout: async () => authBackend.get(`${AUTH_SERVICE_PATH}/logout`),
};

export default authService;
