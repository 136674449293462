import {
  Box, InputAdornment, TextField, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { EditRateForm, UseForm } from '../../../utils/types';

const EditFixRateForm: React.FC<EditFixRateFormProps> = ({
  formValues,
  handleInputChange,
  errors,
}) => {
  const { rate, description } = formValues;

  const numberInputProps = {
    startAdornment: (
      <InputAdornment position='start'>
        <Typography sx={{ fontWeight: 'bold', color: 'black' }}>$</Typography>
      </InputAdornment>
    ),
    inputProps: { min: 1 },
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          item
          spacing={2}
          direction='column'
          alignItems='center'
          justifyContent='space-between'
        >
          <Grid container item spacing={2} direction='column'>
            <Grid item xs={12}>
              <Box display='flex' sx={{ justifyContent: 'space-between' }} flexDirection='row'>
                <Typography
                  id='modal-modal-description'
                  sx={{ mt: 2, ml: 4 }}
                  component='span'
                  fontWeight='bold'
                >
                  Rate
                </Typography>
                <TextField
                  label='Rate'
                  required
                  value={rate}
                  error={Boolean(errors?.rate)}
                  InputProps={numberInputProps}
                  helperText={errors?.rate && errors.rate}
                  onChange={(event) => {
                    handleInputChange({
                      target: {
                        name: 'rate',
                        value: event.target.value.replace(/\s/g, ''),
                      },
                    });
                  }}
                  name='rate'
                  sx={{ width: '240px', minWidth: '200px' }}
                />
              </Box>
              <Box display='flex' sx={{ justifyContent: 'space-between' }} flexDirection='row'>
                <Typography
                  id='modal-modal-description'
                  sx={{ mt: 2, ml: 4 }}
                  component='span'
                  fontWeight='bold'
                >
                  Description
                </Typography>
                <TextField
                  label='Description'
                  multiline
                  rows={4}
                  required
                  value={description}
                  error={Boolean(errors?.description)}
                  helperText={Boolean(errors?.description) && errors?.description}
                  onChange={handleInputChange}
                  type='text'
                  name='description'
                  sx={{ width: '240px', minWidth: '200px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default EditFixRateForm;

type EditFixRateFormProps = {
  handleInputChange: UseForm<EditRateForm>['handleInputChange'];
  formValues: Partial<EditRateForm>;
  errors?: { [key in keyof EditRateForm]?: string };
};

EditFixRateForm.defaultProps = {
  errors: undefined,
};
