import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import Layout from './presentation/templates/Layout';
import LoginPage from './presentation/pages/Login';
import NotFound from './presentation/pages/Messages/NotFound';
import ManualReport from './presentation/pages/ManualReport';
import RatesManager from './presentation/pages/RatesManager';

import { billinglightTheme } from './AppTheme';
import AuthProvider from './auth/provider';
import RoleBaseGuard from './auth/guard/RoleBaseGuard';
import { ROLES } from './utils/types';
import ReportPreviewPage from './presentation/pages/ReportPreview';
import FeatureFlagProvider from './presentation/atoms/featureFlagProvider';
import useFeatureFlag from './hooks/useFeatureFlag';

const App = () => {
  const { getFeatureFlags } = useFeatureFlag();
  return (
    <FeatureFlagProvider fetchFeatureFlags={getFeatureFlags}>
      <Admin
        layout={Layout}
        theme={billinglightTheme}
        loginPage={LoginPage}
        authProvider={AuthProvider}
        catchAll={NotFound}
        requireAuth
      >
        <CustomRoutes>
          <Route
            path=''
            element={(
              <RoleBaseGuard allowedRoles={[ROLES.reporter]}>
                <ManualReport />
              </RoleBaseGuard>
            )}
          />
          <Route
            path='/reportPreview/:date'
            element={(
              <RoleBaseGuard allowedRoles={[ROLES.reporter]}>
                <ReportPreviewPage />
              </RoleBaseGuard>
            )}
          />
          <Route
            path='/ratesManager'
            element={(
              <RoleBaseGuard allowedRoles={[ROLES.viewer, ROLES.rackRatesEditor]}>
                <RatesManager />
              </RoleBaseGuard>
            )}
          />
          <Route path='*' element={<NotFound />} />
        </CustomRoutes>
        <Resource name='' />
      </Admin>
    </FeatureFlagProvider>
  );
};

export default App;
