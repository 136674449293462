import { AppBar, AppBarProps } from 'react-admin';
import {
  Box, useMediaQuery, Theme, Typography, Stack,
} from '@mui/material';
import Logo from '../../../assets/icons/BA_logo.svg';
import { AppColors } from '../../../AppTheme';
import CustomUserMenu from '../UserMenu';

const greetingStyles = {
  fontFamily: 'Roboto',
  fontStyle: 'Light',
  fontWeight: '200',
  fontSize: '24px',
  textAlign: 'right',
  lineHeight: '32px',
  letterSpacing: '0.25px',
};

const appBarStyles = {
  backgroundImage: `linear-gradient(to bottom, ${AppColors.main}, ${AppColors.secondary})`,
  paddingTop: '6px',
  paddingBottom: '6px',
};

const CustomAppBar = (props: AppBarProps) => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  return (
    <AppBar {...props} userMenu={false} sx={appBarStyles}>
      <Box component='img' alt='Billing App Logo' src={Logo} />
      <Stack spacing={0} sx={{ width: '100%' }}>
        <Typography component='p' sx={greetingStyles}>
          Hi,
        </Typography>
      </Stack>
      {isLargeEnough && <Box component='span' sx={{ flex: 1 }} />}
      <CustomUserMenu />
    </AppBar>
  );
};

export default CustomAppBar;
