import React, { useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridEditCellProps,
  GridSortModel,
} from '@mui/x-data-grid';
import ReportPreviewManager from '../../../../../business_logic/managers/ReportPreviewManager';
import useReportPreviewTables from '../../../../../hooks/useReportPreviewTables';

const customLocaleText = {
  noRowsLabel: 'There are no Positions',
};

const columns: GridColDef[] = [
  {
    field: 'client',
    headerName: 'Client',
    headerClassName: 'column-header',
    width: 250,
    disableColumnMenu: false,
    hideable: false,
  },
  {
    field: 'invoice',
    headerName: 'Invoice #',
    headerClassName: 'column-header',
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    headerClassName: 'column-header',
    width: 250,
    disableColumnMenu: true,
  },
  {
    field: 'roleStatus',
    headerName: 'Role Status',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
    cellClassName: (params: GridEditCellProps) =>
      (params.value === '+1 visible' ? 'plus-one-cell' : ''),
  },
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'column-header',
    width: 150,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'roleRank',
    headerName: 'Role Rank',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'role',
    headerName: 'Role',
    headerClassName: 'column-header',
    width: 180,
    disableColumnMenu: true,
  },
  {
    field: 'level',
    headerName: 'Level',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'levelRank',
    headerName: 'Level Rank',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'quantifier',
    headerName: 'Quantifier',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'personTime',
    headerName: 'Person Time',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'loadingFactorForBilling',
    headerName: 'Loading Factor',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    headerClassName: 'column-header',
    width: 120,
    disableColumnMenu: true,
  },
];

const reportPreviewManager = ReportPreviewManager.getInstance();

const ReportPreviewPositions: React.FC = () => {
  const {
    datagridInitState,
    positionsColumnVisibility: initVisibility,
    getRowClassName,
    getSortedRows,
  } = useReportPreviewTables();

  const [rows, setRows] = useState(reportPreviewManager.getAllPositions());
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [visibility, setVisibility] = React.useState<GridColumnVisibilityModel>(initVisibility);

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    const sortedRows = getSortedRows(model, [...rows]);
    setRows(sortedRows);
  };

  return (
    <DataGrid
      rows={rows}
      getRowClassName={(params) => getRowClassName(params, rows)}
      columns={columns}
      columnVisibilityModel={visibility}
      onColumnVisibilityModelChange={(newModel) => setVisibility(newModel)}
      initialState={datagridInitState}
      pageSizeOptions={[25, 50, 100]}
      localeText={customLocaleText}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      disableRowSelectionOnClick
      disableColumnFilter
      disableVirtualization
    />
  );
};

export default ReportPreviewPositions;
