import React, { useState } from 'react';
import {
  Datagrid, TextField, useNotify, usePermissions,
} from 'react-admin';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as yup from 'yup';
import useForm from '../../../../hooks/useForm';
import CustomModal from '../../CustomModal';
import EditFixRateForm from '../../Forms/EditFixRateForm';
import { EditRateForm, ROLES, YupSchemaObject } from '../../../../utils/types';
import { RoleRateItem } from '../../../../business_logic/models/roleRateEntry';
import HistoryRatesManagerTable from '../HistoryRatesManager';
import RowActions from '../RowActions';
import useRolesData from '../../../../hooks/useRolesData';
import { ALLOWED_DECIMAL_PLACES } from '../../../../utils/constants';
import CustomCurrencyField from '../CustomFields/CustomCurrencyField';

const dataGridStyles = {
  width: '85vw',
  maxWidth: '950px',
  '& .RaDatagrid-headerCell': {
    padding: '16px',
    height: 'auto',
  },
  '& .column-actions': {
    textAlign: 'center',
  },
  '& .column-standardRole': {
    maxWidth: 'calc(100vw/6)',
    lineHeight: '.7rem',
  },
};

type ListRatesProps = {
  setData: React.Dispatch<React.SetStateAction<RoleRateItem[]>>;
};

const ListRates: React.FC<ListRatesProps> = ({ setData }) => {
  const notify = useNotify();
  const { updateRoleRate } = useRolesData();
  const { permissions, isLoading: isLoadingPermissions } = usePermissions();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isOpenHistoryModal, setOpenHistoryModal] = useState(false);
  const [historyModalTitle, setHistoryModalTitle] = useState('');
  const [pointerRolRateId, setPointerRolRateId] = useState('');
  const [open, setOpen] = useState(false);
  const [originalRackRate, setOriginalRackRate] = useState<string | undefined>('');
  const [fullWidth] = React.useState(true);
  const defaultEditRoleFormValues: EditRateForm = {
    id: '',
    standardRole: '',
    rate: '',
    description: '',
  };

  const validationSchema: YupSchemaObject<EditRateForm> = {
    id: yup.string().required(),
    standardRole: yup.string().required(),
    rate: yup
      .number()
      .typeError('Field must be a number')
      .min(0.01, 'Only numbers greater than 0')
      .max(99999, 'Max value is 99999')
      .required('Field is required')
      .test(
        'decimal-places',
        `Rate has more than ${ALLOWED_DECIMAL_PLACES} decimal places`,
        (value) => {
          const [, decimalPart] = String(value).split('.');
          return !(decimalPart && decimalPart.length > ALLOWED_DECIMAL_PLACES);
        },
      ),
    description: yup.string().required('Description is required'),
  };

  const updateRoleRateList = (editedRole: EditRateForm) => {
    setData((roleRateList) => {
      const updatedRoleRateList = roleRateList.map((roleRate) =>
        (roleRate.id === editedRole.id ? {
          ...roleRate,
          rate: parseFloat(editedRole.rate).toFixed(ALLOWED_DECIMAL_PLACES),
        }
          : roleRate));
      return [...updatedRoleRateList];
    });
  };

  const {
    formValues: editRoleFormValues,
    handleInputChange: handleEditRoleInputChanges,
    reset: resetEditRoleForm,
    setFormValues: setEditRoleFormValues,
    errors: editRoleFormErrors,
    setErrors: setEditRoleFormErrors,
  } = useForm(defaultEditRoleFormValues, validationSchema);

  const editUserModalProps = {
    title: 'Edit rate',
    description: 'Please, edit the rate for the standard role: ',
    isEditModal: true,
    standardRoleName: editRoleFormValues?.standardRole,
  };

  const HistoryratesManagerProps = {
    titleHistoryModal: 'Rates History',
    title: `${historyModalTitle}`,
    cancelButtonText: 'Close',
  };

  const isEditAvailable = permissions?.roles?.includes(ROLES.rackRatesEditor);

  const checkRackRateValues = (updatedRoleData: EditRateForm) =>
    Number.isNaN(updatedRoleData.rate) || updatedRoleData.rate === ''
    || (originalRackRate && originalRackRate === updatedRoleData.rate);

  const handleUpdateRoleRate = async () => {
    const updatedRoleData = editRoleFormValues;
    if (checkRackRateValues(editRoleFormValues)) {
      editRoleFormValues.rate = '';
      notify('The rack rates values should be changed', { type: 'error' });
      return;
    }
    try {
      await updateRoleRate(editRoleFormValues);
      updateRoleRateList(updatedRoleData);
      resetEditRoleForm();
      setOpenEditModal(false);
      notify('Rack rate successfully updated', { type: 'success' });
    } catch (error) {
      notify('Error on updating rack rate', { type: 'error' });
    }
  };

  const handleEditClick = ({ rate, ...record }: RoleRateItem) => {
    setEditRoleFormValues({
      ...record,
      description: '',
      rate: rate ?? '',
    });
    setOriginalRackRate(rate);
    setOpenEditModal(true);
    setEditRoleFormErrors({});
  };

  const handleHistoryClick = ({ id, standardRole }: RoleRateItem) => {
    setHistoryModalTitle(standardRole);
    setOpenHistoryModal(true);
    setPointerRolRateId(id.toString());
  };

  const isDisableSubmitEditForm = () => {
    let formHasErrors = false;

    if (editRoleFormErrors) {
      formHasErrors = Object.values(editRoleFormErrors).length > 0;
    }

    const formHasEmptyValues = Boolean(
      !editRoleFormValues.id || !editRoleFormValues.description || !editRoleFormValues.rate,
    );

    return formHasErrors || formHasEmptyValues;
  };
  const handleClickOpen = () => {
    if (checkRackRateValues(editRoleFormValues)) {
      editRoleFormValues.rate = '';
      setOpenEditModal(false);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleExit = () => {
    editRoleFormValues.rate = '';
    setOpen(false);
    setOpenEditModal(false);
  };

  window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
    if (!checkRackRateValues(editRoleFormValues)) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  return (
    <>
      <Datagrid
        sx={dataGridStyles}
        bulkActionButtons={false}
        size='small'
        isLoading={isLoadingPermissions}
      >
        <TextField source='standardRole' label='Standard Role' />
        <CustomCurrencyField source='rate' label='Rate' />
        <TextField source='typeOfRate' label='Type of Rate' />

        <RowActions
          label='Actions'
          source='actions'
          actions={[
            {
              actionType: 'editRackRateValues',
              onClick: handleEditClick,
              hidden: !isEditAvailable,
            },
            {
              actionType: 'history',
              onClick: handleHistoryClick,
            },
          ]}
        />
      </Datagrid>

      <CustomModal
        {...editUserModalProps}
        open={openEditModal}
        handleClose={() => handleClickOpen()}
        handleConfirm={handleUpdateRoleRate}
        handleCancel={() => handleClickOpen()}
        disabledConfirmButton={isDisableSubmitEditForm()}
        variant='outlined'
        confirmButtonColor='primary'
      >
        <EditFixRateForm
          formValues={editRoleFormValues}
          handleInputChange={handleEditRoleInputChanges}
          errors={editRoleFormErrors}
        />
      </CustomModal>

      <CustomModal
        {...HistoryratesManagerProps}
        width='auto'
        open={isOpenHistoryModal}
        handleClose={() => setOpenHistoryModal(false)}
        handleCancel={() => setOpenHistoryModal(false)}
        variant='outlined'
        confirmButtonColor='success'
      >
        <HistoryRatesManagerTable pointerRolRateId={pointerRolRateId} />
      </CustomModal>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth='xs'
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle sx={{ ml: 16 }} id='responsive-dialog-title'>
          Unsaved changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ ml: 15 }}>Your changes will be lost</DialogContentText>
          <DialogContentText sx={{ ml: 12 }}>Are you sure you want to leave?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleExit}
            variant='outlined'
            color='primary'
            startIcon={<CheckCircleIcon />}
            autoFocus
          >
            Leave
          </Button>
          <Button
            autoFocus
            onClick={handleClose}
            variant='outlined'
            color='primary'
            startIcon={<CancelIcon />}
          >
            Continue editing
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ListRates;
