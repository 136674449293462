enum ROLES {
  viewer = 'viewer',
  rackRatesEditor = 'fixed-rates-editor',
  reporter = 'reporter',
}

enum PERIODS {
  Monthly = 'Monthly',
  Hourly = 'Hourly',
}

/* eslint-disable */
enum FILE_ERRORS {
  NOT_FOUND = 'This record does not have a pdf file',
  FETCH_ERROR = 'Error fetching pdf file. please try again',
}

export { ROLES, PERIODS, FILE_ERRORS };
