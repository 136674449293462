import { ROLES } from './types';

const ALL_ROLES = [ROLES.reporter, ROLES.viewer, ROLES.rackRatesEditor];

export const DEVELOPER_ROLES = [
  'Software Architect',
  'Tech Lead',
  'Front End Developer',
  'Back End Developer',
];

export const QUANTIFIERS = ['Monthly', 'Hourly'];

export const ALLOWED_DECIMAL_PLACES = 2;

export default ALL_ROLES;
