/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ListContextProvider, PublicFieldProps, useList } from 'react-admin';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';
import {
  RoleRateHistoryList,
  RoleRateHistoryEntry,
  RoleRateHistoryFilter,
  RoleRateHistoryItem,
} from '../../../../business_logic/models/roleRateEntry';
import ListHistoryRates from './ListHistoryRates';
import useRolesData from '../../../../hooks/useRolesData';

const MAX_MODAL_HEIGTH = '60vh';
const containerHistoryTableStyle = {
  maxHeight: MAX_MODAL_HEIGTH,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '0.3rem',
  },
  '&::-webkit-scrollbar-track': {
    WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#EB505D',
    outline: 'none',
    borderRadius: 10,
  },
};

type HistoryTableProps = {
  pointerRolRateId: string;
} & PublicFieldProps;

const HistoryRatesManagerTable: React.FC<HistoryTableProps> = ({ pointerRolRateId }) => {
  const { getRoleHistoryData } = useRolesData();
  const [roleRateHistory, setRoleRateHistory] = useState<RoleRateHistoryList>([]);
  const listContext = useList({
    data: roleRateHistory,
    sort: { field: 'creationDate', order: 'DESC' },
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const roleRateHistoryFilter: RoleRateHistoryFilter = {
        entity_uuid: pointerRolRateId,
      };
      try {
        const fetchedRolesHistory = await getRoleHistoryData(roleRateHistoryFilter);
        const rolesHistory: RoleRateHistoryItem[] = fetchedRolesHistory.map(
          (roleRateHistoryEntry: RoleRateHistoryEntry) => {
            const newerRateIndex = 1;

            return {
              id: roleRateHistoryEntry.id,
              creationDate: new Date(roleRateHistoryEntry.creation_date * 1000),
              creator: roleRateHistoryEntry.creator.name,
              description: roleRateHistoryEntry.description,
              rate: roleRateHistoryEntry.changes.rate[newerRateIndex],
              files: roleRateHistoryEntry?.files,
            };
          },
        );
        setRoleRateHistory(rolesHistory);
        setIsLoading(false);
      } catch (error) {
        setHasError(true);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  if (hasError) {
    return <Typography>Error get data from API</Typography>;
  }

  if (isLoading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        height='5vh'
        marginTop={3}
        marginBottom={3}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
      }}
    >
      <Box sx={containerHistoryTableStyle}>
        <ListHistoryRates />
      </Box>
    </ListContextProvider>
  );
};

export default HistoryRatesManagerTable;
