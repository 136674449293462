import { useEffect } from 'react';
import { Box, Stack, Button } from '@mui/material';
import { Loading, useAuthState } from 'react-admin';
import { useNavigate } from 'react-router';
import Logo from '../../../assets/images/ioet.png';
import AuthConfig from '../../../auth/config';

const LoginPage = () => {
  const { isLoading, authenticated } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && authenticated) {
      navigate('/', { replace: true });
    }
  }, [isLoading, authenticated, navigate]);

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      sx={{
        height: '100vh',
      }}
    >
      {!authenticated ? (
        <>
          <Box component='img' alt='ioet Logo' src={Logo} />
          <Button variant='contained' href={AuthConfig.authServiceLoginURL}>
            Login
          </Button>
        </>
      ) : (
        <Loading />
      )}
    </Stack>
  );
};

export default LoginPage;
