import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { PropsWithChildren } from 'react';

const titleStyles = {
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
};

const containerButtonsStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '10px',
};

const CustomModal: React.FC<PropsWithChildren<CustomModalProps>> = ({
  open,
  handleClose,
  title,
  titleHistoryModal,
  isEditModal,
  standardRoleName,
  description,
  confirmButtonText,
  handleConfirm,
  cancelButtonText,
  handleCancel,
  confirmButtonIcon,
  children,
  width,
  requiredIndication,
  cancelButtonColor,
  confirmButtonColor,
  disabledConfirmButton,
  isLoading,
  variant,
}) => (
  <Modal
    open={open}
    onClose={(_, reason) => {
      if (isLoading && reason === 'backdropClick') return;
      handleClose();
    }}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
  >
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width,
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
      }}
    >
      {titleHistoryModal && typeof titleHistoryModal === 'string' ? (
        <Typography variant='h5' sx={titleStyles}>
          {titleHistoryModal}
        </Typography>
      ) : (
        titleHistoryModal && titleHistoryModal
      )}

      <h3>{title}</h3>
      {standardRoleName ? (
        <Typography id='modal-modal-description' sx={{ mt: 2 }} component='span'>
          {description}
          <b>{standardRoleName}</b>
        </Typography>
      ) : (
        <Typography id='modal-modal-description' sx={{ mt: 2 }} component='span'>
          {description}
        </Typography>
      )}
      <Typography variant='body2' sx={{ mt: 2 }}>
        {requiredIndication}
      </Typography>

      {children}

      <Box sx={containerButtonsStyles}>
        <Button
          variant={variant}
          onClick={handleCancel}
          color={cancelButtonColor}
          startIcon={<HighlightOffIcon />}
          size='small'
          style={{ marginRight: '5px' }}
        >
          {cancelButtonText}
        </Button>
        {isEditModal && (
          <Button
            variant={variant}
            disabled={disabledConfirmButton}
            color={confirmButtonColor}
            onClick={handleConfirm}
            startIcon={confirmButtonIcon}
            size='small'
            aria-label='save'
          >
            {isLoading ? <CircularProgress size='1.5em' /> : confirmButtonText}
          </Button>
        )}
      </Box>
    </Box>
  </Modal>
);

export default CustomModal;

export type CustomModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  titleHistoryModal?: string | React.ReactElement<unknown, React.JSXElementConstructor<unknown>>;
  description?: string;
  confirmButtonText?: string;
  handleConfirm?: () => void;
  cancelButtonText?: string;
  handleCancel?: () => void;
  confirmButtonIcon?: React.ReactNode;
  requiredIndication?: string;
  width?: string | number;
  cancelButtonColor?: ButtonProps['color'];
  confirmButtonColor?: ButtonProps['color'];
  disabledConfirmButton?: boolean;
  isLoading?: boolean;
  isEditModal?: boolean;
  standardRoleName?: string;
  variant?: ButtonProps['variant'];
};

CustomModal.defaultProps = {
  titleHistoryModal: '',
  description: '',
  width: 400,
  handleConfirm: () => {},
  handleCancel: () => {},
  requiredIndication: '',
  cancelButtonColor: 'error',
  confirmButtonColor: 'success',
  disabledConfirmButton: false,
  isLoading: false,
  isEditModal: false,
  standardRoleName: '',
  variant: 'contained',
  confirmButtonText: 'Save',
  cancelButtonText: 'Cancel',
  confirmButtonIcon: <SaveIcon />,
};
