const useFileNameDateValidator = () => {
  const ERROR_MESSAGE = 'The selected date and the date in the name of the files must match';

  const getCharsBetweenFirstNumberAndNextPoint = (fileName: string): string => {
    const startIndex = fileName.search(/\d/);
    if (startIndex === -1) {
      return '';
    }

    const endIndex = fileName.indexOf('.', startIndex);
    if (endIndex === -1) {
      return '';
    }

    return fileName.substring(startIndex, endIndex);
  };

  const getDateFromFileName = (fileName: string): Date | null => {
    const name = getCharsBetweenFirstNumberAndNextPoint(fileName);
    if (!name) {
      return null;
    }

    const [yearString, monthString] = name.split('-');

    const monthNames = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ];
    const monthIndex = monthNames.indexOf(monthString.toLowerCase());

    const year = parseInt(yearString, 10);

    if (monthIndex !== -1 && !Number.isNaN(year)) {
      return new Date(year, monthIndex);
    }
    return null;
  };

  const fileDateMatchesSelectedPeriod = (fileDate: Date, selectedPeriod: Date): boolean =>
    fileDate.getMonth() === selectedPeriod.getMonth()
    && fileDate.getFullYear() === selectedPeriod.getFullYear();

  const hasValidDate = (file: File, selectedDate: Date): boolean => {
    const reportDate = getDateFromFileName(file.name);
    if (reportDate) {
      return fileDateMatchesSelectedPeriod(reportDate, selectedDate);
    }

    return false;
  };

  return { hasValidDate, ERROR_MESSAGE };
};

export default useFileNameDateValidator;
