import useAxiosInstance from './useAxiosInstance';
import { RoleRateHistoryFilter } from '../business_logic/models/roleRateEntry';

interface RoleRateHistoryProps {
  baseURL: string;
}

const useRoleRateHistoryRepository = ({ baseURL }: RoleRateHistoryProps) => {
  const { axiosInstance } = useAxiosInstance({ baseURL });

  async function getRoleRatesHistory(url: string, filter: RoleRateHistoryFilter) {
    const { data } = await axiosInstance.post(url, filter);
    return data;
  }

  return { getRoleRatesHistory };
};

export default useRoleRateHistoryRepository;
