import {
  PositionFormatted,
  PtoFormatted,
  ReportPreviewFormatted,
  WarningFormatted,
} from '../models/reportPreview';

class ReportPreviewManager {
  private report: ReportPreviewFormatted = {
    date: '',
    positions: {},
    ptoDetails: {},
    warnings: {},
  };

  private static instance: ReportPreviewManager | null = null;

  static getInstance(): ReportPreviewManager {
    if (!ReportPreviewManager.instance) {
      ReportPreviewManager.instance = new ReportPreviewManager();
    }
    return ReportPreviewManager.instance;
  }

  private save(): void {
    localStorage.setItem('report', JSON.stringify(this.report));
  }

  private static get(): ReportPreviewFormatted {
    return JSON.parse(localStorage.getItem('report') ?? '');
  }

  private init(): void {
    const report = ReportPreviewManager.get();
    this.setReport(report);
  }

  setReport(report: ReportPreviewFormatted): void {
    this.report = report;
    this.save();
  }

  resetReport(): void {
    this.report.positions = {};
    this.report.ptoDetails = {};
    this.report.warnings = {};
    this.save();
  }

  getDate(): string {
    this.init();
    return this.report.date;
  }

  getPosition(key: string): PositionFormatted | null {
    this.init();
    return this.report.positions[key] || null;
  }

  getAllPositions(): PositionFormatted[] {
    this.init();
    return Object.values(this.report.positions);
  }

  addPosition(position: PositionFormatted): boolean {
    this.init();
    const key = position.id;
    if (!(key in this.report.positions)) {
      this.report.positions[key] = position;
      this.save();
      return true;
    }
    return false;
  }

  updatePosition(updatedPosition: PositionFormatted): boolean {
    this.init();
    const key = updatedPosition.id;
    if (key in this.report.positions) {
      this.report.positions[key] = updatedPosition;
      this.save();
      return true;
    }
    return false;
  }

  deletePosition(key: string): boolean {
    this.init();
    if (key in this.report.positions) {
      delete this.report.positions[key];
      this.save();
      return true;
    }
    return false;
  }

  getPto(key: string): PtoFormatted | null {
    this.init();
    return this.report.ptoDetails[key] || null;
  }

  getAllPtos(): PtoFormatted[] {
    this.init();
    return Object.values(this.report.ptoDetails);
  }

  addPto(pto: PtoFormatted): boolean {
    this.init();
    const key = pto.id;
    if (!(key in this.report.ptoDetails)) {
      this.report.ptoDetails[key] = pto;
      this.save();
      return true;
    }
    return false;
  }

  updatePto(updatedPto: PtoFormatted): boolean {
    this.init();
    const key = updatedPto.id;
    if (key in this.report.ptoDetails) {
      this.report.ptoDetails[key] = updatedPto;
      this.save();
      return true;
    }
    return false;
  }

  deletePto(key: string): boolean {
    this.init();
    if (key in this.report.ptoDetails) {
      delete this.report.ptoDetails[key];
      this.save();
      return true;
    }
    return false;
  }

  getWarning(key: string): WarningFormatted | null {
    this.init();
    return this.report.warnings[key] || null;
  }

  getAllWarnings(): WarningFormatted[] {
    this.init();
    return Object.values(this.report.warnings);
  }

  addWarning(warning: WarningFormatted): boolean {
    this.init();
    const key = warning.id;
    if (!(key in this.report.warnings)) {
      this.report.warnings[key] = warning;
      this.save();
      return true;
    }
    return false;
  }

  updateWarning(updatedWarning: WarningFormatted): boolean {
    this.init();
    const key = updatedWarning.id;
    if (key in this.report.warnings) {
      this.report.warnings[key] = updatedWarning;
      this.save();
      return true;
    }
    return false;
  }

  deleteWarning(key: string): boolean {
    this.init();
    if (key in this.report.warnings) {
      delete this.report.warnings[key];
      this.save();
      return true;
    }
    return false;
  }
}

export default ReportPreviewManager;
