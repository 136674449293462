import DateMethods from '../utils/dateMethods';

const useDateValidations = (
  fromDates: string[],
  toDates: string[],
  otherFromDatesArray: string[],
  otherToDatesArray: string[],
) => {
  const hasOverlapDates = (range1Start: Date, range1End: Date, index: number) => {
    for (let i = 0; i < fromDates.length; i += 1) {
      if (i !== index || index === -1) {
        const range2Start = new Date(DateMethods.addZeroTime(fromDates[i]));
        const range2End = new Date(DateMethods.addZeroTime(toDates[i]));

        if (!DateMethods.notOverlap(range1Start, range1End, range2Start, range2End)) {
          return true;
        }
      }
    }

    for (let i = 0; i < otherFromDatesArray.length; i += 1) {
      const range2Start = new Date(DateMethods.addZeroTime(otherFromDatesArray[i]));
      const range2End = new Date(DateMethods.addZeroTime(otherToDatesArray[i]));

      if (!DateMethods.notOverlap(range1Start, range1End, range2Start, range2End)) {
        return true;
      }
    }
    return false;
  };

  const isDateAvailable = (date: string, showError: (message: string) => void) => {
    const posibleNewDate = new Date(DateMethods.addZeroTime(date));
    if (hasOverlapDates(posibleNewDate, posibleNewDate, -1)) {
      showError('Overlap date');
      return false;
    }
    return true;
  };

  const validateFromDate = (
    fromDate: string,
    index: number,
    showError: (message: string) => void,
  ) => {
    const from = new Date(DateMethods.addZeroTime(fromDate));
    const to = new Date(DateMethods.addZeroTime(toDates[index]));

    let error = '';
    if (!DateMethods.hasValidDateFormat(fromDate)) {
      error = 'Invalid format';
    } else if (from > to) {
      error = 'Higher than TO date';
    } else if (hasOverlapDates(from, to, index)) {
      error = 'Overlap date';
    }

    return error === '' ? true : (showError(error), false);
  };

  const validateToDate = (toDate: string, index: number, showError: (message: string) => void) => {
    const to = new Date(DateMethods.addZeroTime(toDate));
    const from = new Date(DateMethods.addZeroTime(fromDates[index]));

    let error = '';
    if (!DateMethods.hasValidDateFormat(toDate)) {
      error = 'Invalid format';
    } else if (to < from) {
      error = 'Lower than From date';
    } else if (hasOverlapDates(from, to, index)) {
      error = 'Overlap date';
    }

    return error === '' ? true : (showError(error), false);
  };

  return {
    isDateAvailable,
    validateFromDate,
    validateToDate,
  };
};

export default useDateValidations;
