import {
  ReportPreviewFormatted,
  PtoRaw,
  PositionRaw,
  ReportPreviewRaw,
  WarningRaw,
  PositionFormatted,
  PtoFormatted,
  WarningFormatted,
} from '../../business_logic/models/reportPreview';
import DateMethods from '../dateMethods';

interface KeyRegistryType {
  [key: string]: number;
}

let keyRegistry: KeyRegistryType = {};

const ReportPreviewParser = {
  getKey(value: PositionFormatted | PtoFormatted | WarningFormatted): string {
    const key = `${value.client}${value.projectName}${value.employeeId}`
      .toLowerCase()
      .replace(/\s+/g, '');
    const count = (keyRegistry[key] || 0) + 1;
    keyRegistry[key] = count;
    return `${key}_${count}`;
  },

  parseToReportPreviewFormatted(
    reportPreviewRaw: ReportPreviewRaw,
    reportDate: Date,
  ): ReportPreviewFormatted {
    const {
      positions: rawReport,
      pto_details: rawPtoDetails,
      warnings: rawWarnings,
    } = reportPreviewRaw;

    const formattedReport: { [key: string]: PositionFormatted } = {};
    const formattedPtoDetails: { [key: string]: PtoFormatted } = {};
    const formattedWarnings: { [key: string]: WarningFormatted } = {};

    keyRegistry = {};
    rawReport.forEach((position: PositionRaw) => {
      const value = {
        id: '',
        client: position.client,
        invoice: position.invoice,
        projectName: position.project_name,
        roleStatus: position.role_status,
        name: position.name,
        roleRank: position.role_rank,
        role: position.role,
        levelRank: position.level_rank,
        level: position.level,
        quantifier: position.quantifier,
        loadingFactorForBilling: position.loading_factor_for_billing,
        personTime: position.person_time,
        startDate: DateMethods.toStandardFormat(position.start_date),
        endDate: DateMethods.toStandardFormat(position.end_date),
        employeeId: position.employee_id,
      };
      const key = ReportPreviewParser.getKey(value);
      value.id = key;
      formattedReport[key] = value;
    });

    keyRegistry = {};
    rawPtoDetails.forEach((pto: PtoRaw) => {
      const value = {
        id: '',
        client: pto.client,
        invoice: pto.invoice,
        projectName: pto.project_name,
        roleStatus: pto.role_status,
        name: `${pto.lastname} ${pto.name}`,
        roleRank: pto.role_rank,
        role: pto.role,
        levelRank: pto.level_rank,
        level: pto.level,
        quantifier: pto.quantifier,
        startDate: DateMethods.toStandardFormat(pto.start_date),
        endDate: DateMethods.toStandardFormat(pto.end_date),
        workingDays: pto.working_days,
        ptoPlusUto: pto.pto_plus_uto,
        ptoPlusUtoDates: {
          from: DateMethods.formatDatesArray(pto.from),
          to: DateMethods.formatDatesArray(pto.to),
        },
        ptoOther: pto.pto_other,
        ptoOtherDates: {
          from: DateMethods.formatDatesArray(pto.from2),
          to: DateMethods.formatDatesArray(pto.to2),
        },
        holidays: pto.holidays,
        date: DateMethods.formatHolidaysArray(pto.date),
        personTime: pto.person_time,
        totalBilledDays: pto.total_billed_days,
        employeeId: pto.employee_id,
      };
      const key = ReportPreviewParser.getKey(value);
      value.id = key;
      formattedPtoDetails[key] = value;
    });

    keyRegistry = {};
    rawWarnings.forEach((warning: WarningRaw) => {
      const value = {
        id: '',
        name: `${warning.lastname} ${warning.name}`,
        client: warning.client_name,
        projectName: warning.project_name,
        description: warning.description,
        employeeId: warning.employee_id,
      };
      const key = ReportPreviewParser.getKey(value);
      value.id = key;
      formattedWarnings[key] = value;
    });
    return {
      date: DateMethods.datetoString(reportDate),
      positions: formattedReport,
      ptoDetails: formattedPtoDetails,
      warnings: formattedWarnings,
    };
  },
};

export default ReportPreviewParser;
