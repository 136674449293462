import { useState } from 'react';
import useReportRepository from './useReportRepository';

import Config from '../utils/config';

const useDownloadFile = () => {
  const { getReport, getManualReport } = useReportRepository({ baseURL: Config.backendUrl });
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadFile = (data: any, fileName: string) => {
    const urlLink = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = urlLink;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(urlLink);
  };

  const getFile = async (url: string, fileName: string) => {
    setLoading(true);
    try {
      const data = await getReport(url, fileName);
      downloadFile(data, fileName);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getManualReportFile = async (
    url: string,
    fileName: string,
    notionfile: File,
    bamboofile: File,
    reportInHoursFile?: File,
  ) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('notion_file', notionfile);
    formData.append('bamboo_file', bamboofile);
    if (reportInHoursFile) {
      formData.append('report_in_hours_file', reportInHoursFile);
    }
    try {
      const data = await getManualReport(url, fileName, formData);
      downloadFile(data, fileName);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error('The report was not generated, please check the files');
    }
  };

  return {
    getFile,
    downloadFile,
    getManualReportFile,
    loading,
  };
};

export default useDownloadFile;
