import React from 'react';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Config from '../../../utils/config';

type DatePickerProps = {
  date: Date | null;
  handleChange: (date: Date | null) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({ date, handleChange }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePickerMUI
      openTo='month'
      views={['year', 'month']}
      minDate={new Date(Config.dataPickerMinDate)}
      maxDate={new Date()}
      components={{
        OpenPickerIcon: ArrowDropDownIcon,
      }}
      value={date}
      onChange={handleChange}
    />
  </LocalizationProvider>
);

export default DatePicker;
