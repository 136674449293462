import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

type SearchInputProps = {
  searchQuery: string;
  placeholder?: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};

const SearchInput: React.FC<SearchInputProps> = ({ searchQuery, setSearchQuery, placeholder }) => (
  <TextField
    autoFocus
    fullWidth
    sx={{ borderRadius: 5, maxWidth: 400 }}
    value={searchQuery}
    placeholder={placeholder}
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position='end'>
          {searchQuery && (
            <ClearIcon onClick={() => setSearchQuery('')} sx={{ cursor: 'pointer' }} />
          )}
        </InputAdornment>
      ),
    }}
    onChange={(e) => setSearchQuery(e.currentTarget.value)}
  />
);

SearchInput.defaultProps = {
  placeholder: 'Search ...',
};

export default SearchInput;
