import { MenuItemLink, MenuProps, useSidebarState } from 'react-admin';

import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import useFeatureFlag from '../../../hooks/useFeatureFlag';

const Menu = ({ dense = false }: MenuProps) => {
  const [open] = useSidebarState();

  const { getToggleFlag } = useFeatureFlag();

  const activateAutomaticReport = getToggleFlag(
    'isActiveAutomaticReport',
    () => (
      <MenuItemLink
        to='/'
        state={{ _scrollToTop: true }}
        primaryText='Generate Report'
        leftIcon={<AddIcon sx={{ my: 1 }} />}
        dense={dense}
      />
    ),
    () => null,
  );

  return (
    <Box
      sx={{
        width: open ? 199 : 54,
        height: '100%',
        marginTop: '13px',
        borderRight: '0.5px solid rgba(0, 0, 0, 0.1)',
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {activateAutomaticReport()}
      <MenuItemLink
        to='/'
        state={{ _scrollToTop: true }}
        primaryText='Manual Report'
        leftIcon={<BackupRoundedIcon sx={{ my: 1 }} />}
        dense={dense}
      />
      <MenuItemLink
        to='/ratesManager'
        state={{ _scrollToTop: true }}
        primaryText='Manage Rates'
        leftIcon={<RequestQuoteIcon sx={{ my: 1 }} />}
        dense={dense}
      />
    </Box>
  );
};

export default Menu;
