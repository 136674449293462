import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const containerStyles = {
  marginTop: '2rem',
  marginBottom: '1.5rem',
  display: 'flex',
  width: 'auto',
};

const titleStyles = {
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: 'black',
  textTransform: 'none',
};
const iconStyles = {
  color: 'black',
};

type RollBackProps = {
  path: string;
  message: string;
  forward: boolean;
};

const NavArrow: React.FC<RollBackProps> = ({ path, message, forward }) => {
  const navigate = useNavigate();
  return (
    <Box sx={containerStyles}>
      <Button onClick={() => navigate(path)}>
        {forward ? (
          <>
            <Typography sx={titleStyles} variant='h6'>
              {message}
            </Typography>
            <ArrowForwardIosIcon sx={iconStyles} />
          </>
        ) : (
          <>
            <ArrowBackIosIcon sx={iconStyles} />
            <Typography sx={titleStyles} variant='h6'>
              {message}
            </Typography>
          </>
        )}
      </Button>
    </Box>
  );
};

export default NavArrow;
