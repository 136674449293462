import { Typography } from '@mui/material';
import React from 'react';

interface ErrorComponentProps {
  error: string;
  visible: boolean;
}

const DateError: React.FC<ErrorComponentProps> = ({ error, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <Typography sx={{ marginLeft: '3rem' }} variant='h6' color='red' align='center'>
      {error}
    </Typography>
  );
};

export default DateError;
