import React from 'react';
import { Datagrid, DateField, TextField } from 'react-admin';
import CustomCurrencyField from '../CustomFields/CustomCurrencyField';

const dataGridStyles = {
  width: '80vw',
  maxWidth: '900px',
  '& .RaDatagrid-thead': {
    backgroundColor: 'primary.main',
  },
  '& .RaDatagrid-headerCell': {
    textAlign: 'left',
    padding: '16px',
    height: 'auto',
  },
  '& .RaDatagrid-rowCell': {
    textAlign: 'left',
  },
  '& .column-creationDate': {
    maxWidth: 'calc(100vw/35)',
  },
  '& .column-description': {
    maxWidth: 'calc(100vw/8)',
    span: {
      '&::-webkit-scrollbar': {
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: 10,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#EB505D',
        borderRadius: 10,
      },
    },
  },
};

const ListHistoryRates: React.FC = () => (
  <Datagrid bulkActionButtons={false} size='medium' sx={dataGridStyles}>
    <DateField locales='en-US' source='creationDate' label='Date (MM/DD/YYYY)' />
    <CustomCurrencyField source='rate' label='Rate' />
    <TextField source='creator' label='Creator' />
    <TextField
      source='description'
      label='Description'
      emptyText='N/A'
      sx={{ display: 'block', maxHeight: '60px', overflowY: 'scroll' }}
    />
  </Datagrid>
);

export default ListHistoryRates;
