const Config = {
  dataPickerMinDate: process.env.REACT_APP_DATA_PICKER_MIN_DATE || '',
  authURL: process.env.REACT_APP_AUTH_URL || '',
  backendUrl: process.env.REACT_APP_BACKEND_URL || '',
  appName:
    process.env.REACT_APP_ENVIRONMENT?.toLowerCase() === 'qa'
      ? process.env.REACT_APP_QA_APP_NAME || ''
      : process.env.REACT_APP_NAME || '',
  numberOfFiles: 1,
  isLocalEnvironment: process.env.REACT_APP_IS_LOCAL_ENVIRONMENT || '',
};

export default Object.freeze(Config);
