import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import DateMethods from '../../../../../utils/dateMethods';
import usePtoDetailsDate from '../../../../../hooks/usePtoDetailsDate';

const boxDatesStyle = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  gap: '.5rem',
};

const datePickerStyle = {
  '.MuiInputBase-input': {
    padding: '.2rem',
  },
};

const buttonDeleteStyle = {
  height: '1.5rem',
  minWidth: '2.3rem',
  width: '2rem',
  marginTop: '.2rem',
};

type RangeType = {
  min: string;
  max: string;
};

type DateChangeType = (newDate: string, index: number) => void;

interface DateListProps {
  fromDates: string[];
  toDates: string[];
  startDate: string;
  endDate: string;
  handleFromDateChange: DateChangeType;
  handleToDateChange: DateChangeType;
  handleDeleteDate: (index: number) => void;
  range: RangeType;
}

const DateList: React.FC<DateListProps> = ({
  fromDates,
  toDates,
  startDate,
  endDate,
  handleFromDateChange,
  handleToDateChange,
  handleDeleteDate,
  range,
}: DateListProps) => {
  const minAllowDate = startDate.localeCompare(range.min) < 0
    ? new Date(DateMethods.addZeroTime(range.min))
    : new Date(DateMethods.addZeroTime(startDate));

  const maxAllowDate = endDate
    ? new Date(DateMethods.addZeroTime(endDate))
    : new Date(DateMethods.addZeroTime(range.max));

  const getSlotProps = (date: string) => ({
    textField: {
      readOnly: true,
      InputProps: {
        value: date,
      },
    },
  });

  const handleChange = (newDate: Date | null, index: number, update: DateChangeType) => {
    if (newDate && !Number.isNaN(newDate.getTime())) {
      update(DateMethods.datetoString(newDate), index);
    }
  };

  const { isWeekend } = usePtoDetailsDate();

  return (
    <Box>
      {fromDates.map((fromDate: string, index: number) => {
        const currentFromDate = new Date(DateMethods.addZeroTime(fromDate));
        const currentToDate = new Date(DateMethods.addZeroTime(toDates[index]));
        return (
          <Box sx={boxDatesStyle} key={fromDate}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datePickerStyle}
                  format='yyyy-MM-dd'
                  value={currentFromDate}
                  onChange={(newDate) => handleChange(newDate, index, handleFromDateChange)}
                  minDate={minAllowDate}
                  maxDate={maxAllowDate}
                  slotProps={getSlotProps(fromDate)}
                  shouldDisableDate={isWeekend}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datePickerStyle}
                  format='yyyy-MM-dd'
                  value={currentToDate}
                  onChange={(newDate) => handleChange(newDate, index, handleToDateChange)}
                  minDate={minAllowDate}
                  maxDate={maxAllowDate}
                  slotProps={getSlotProps(toDates[index])}
                  shouldDisableDate={isWeekend}
                />
              </LocalizationProvider>
            </Box>
            <Button
              variant='contained'
              sx={buttonDeleteStyle}
              onClick={() => handleDeleteDate(index)}
            >
              <DeleteIcon />
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};
export default DateList;
