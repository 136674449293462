import useAxiosInstance from './useAxiosInstance';

interface ReportPreviewProps {
  baseURL: string;
}

const useReportPreview = ({ baseURL }: ReportPreviewProps) => {
  const { axiosInstance } = useAxiosInstance({ baseURL });

  const getReportPreview = async (url: string, files: FormData) => {
    const { data } = await axiosInstance.post(url, files);
    return data;
  };

  return { getReportPreview };
};

export default useReportPreview;
