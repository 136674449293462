/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GridColDef, GridColumnVisibilityModel, GridEditCellProps, GridSortModel,
} from '@mui/x-data-grid';
import ReportPreviewManager from '../business_logic/managers/ReportPreviewManager';
import DateMethods from '../utils/dateMethods';
import { PositionFormatted, PtoFormatted } from '../business_logic/models/reportPreview';

const useReportPreviewTables = () => {
  const reportPreviewManager = ReportPreviewManager.getInstance();

  const getRowIndex = (
    rows: PositionFormatted[] | PtoFormatted[],
    id: string,
  ) => rows.findIndex((row) => row.id === id);

  const hasBreakline = (
    rows: PositionFormatted[] | PtoFormatted[],
    id: string,
    client: string,
  ) => {
    const rawIndex = getRowIndex(rows, id);
    const index = (rawIndex) > 0 ? rawIndex - 1 : rawIndex;
    return rows[index].client !== client;
  };

  const getColumnWidth = (columns: GridColDef[], columnVisibility: GridColumnVisibilityModel) => {
    let totalWidth = 0;
    columns.forEach((column) => {
      if (columnVisibility[column.field]) {
        totalWidth += column.width ? column.width : 0;
      }
    });
    return totalWidth;
  };

  const getRowHeight = (currentDates: string[], otherDates: string[]) => {
    const currentDatesHeight = currentDates.reduce((accumulator: number) => accumulator + 40, 55);
    const otherDatesHeight = otherDates.reduce((accumulator: number) => accumulator + 40, 55);
    return Math.max(currentDatesHeight, otherDatesHeight);
  };

  const getRowClassName = (
    params: GridEditCellProps,
    rows: PositionFormatted[] | PtoFormatted[],
  ) => {
    const { row } = params;
    const { startDate, endDate } = row;
    const [firstDayOfMonth, lastDayOfMonth] = DateMethods.getFirstAndLastDayOfMonth(
      reportPreviewManager.getDate(),
    );
    const formatFirstDayOfMonth = new Date(DateMethods.addZeroTime(firstDayOfMonth));
    const formatLastDayOfMonth = new Date(DateMethods.addZeroTime(lastDayOfMonth));

    const formatEndDate = endDate ? new Date(DateMethods.addZeroTime(endDate)) : null;
    const formatStartDate = startDate ? new Date(DateMethods.addZeroTime(startDate)) : null;

    let className = '';

    if (
      formatEndDate
      && (formatEndDate < formatFirstDayOfMonth || formatEndDate <= formatLastDayOfMonth)
    ) {
      className += 'highlight-red-row';
    }

    if (formatStartDate && formatStartDate >= formatFirstDayOfMonth) {
      className += 'highlight-green-row';
    }

    if (hasBreakline(rows, row.id, row.client)) {
      className += (className) ? '-breakline' : 'breakline';
    }

    return className;
  };

  const getSortedRows = (model: GridSortModel, rows: any) => {
    model.forEach((sortItem) => {
      const { field, sort } = sortItem;
      rows.sort((a: any, b: any) => {
        const aValue = a[field];
        const bValue = b[field];
        if (sort === 'asc') {
          return aValue.localeCompare(bValue);
        }
        return bValue.localeCompare(aValue);
      });
    });

    return rows;
  };

  const positionsColumnVisibility = {
    client: true,
    invoice: false,
    projectName: true,
    roleStatus: true,
    name: true,
    roleRank: false,
    role: false,
    level: false,
    levelRank: false,
    quantifier: true,
    personTime: true,
    loadingFactorForBilling: true,
    startDate: true,
    endDate: true,
  };

  const ptoDetailsColumnVisibility = {
    name: true,
    roleStatus: true,
    workingDays: true,
    ptoPlusUto: true,
    ptoPlusUtoDates: true,
    ptoOther: true,
    ptoOtherDates: true,
    holidays: true,
    date: true,
    personTime: true,
    totalBilledDays: true,
  };

  const warningsColumnVisibility = {
    name: true,
    client: true,
    projectName: true,
    description: true,
  };

  const datagridInitState = {
    pagination: {
      paginationModel: {
        pageSize: 100,
      },
    },
  };

  return {
    getColumnWidth,
    getRowHeight,
    getRowClassName,
    getSortedRows,
    positionsColumnVisibility,
    ptoDetailsColumnVisibility,
    warningsColumnVisibility,
    datagridInitState,
  };
};

export default useReportPreviewTables;
