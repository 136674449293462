import {
  RoleRateFilter,
  RoleRateHistoryEntry,
  RoleRateHistoryFilter,
  RoleRateItem,
  RoleRateStatistics,
} from '../business_logic/models/roleRateEntry';
import useRoleRateHistoryRepository from './useRoleRateHistoryRepository';
import MoneyMethods from '../utils/moneyMethods';

import Config from '../utils/config';
import { ClientRoleRateReq, CreateNewRateForm, EditRateForm } from '../utils/types';
import useRoleRatesRepository from './useRoleRatesRepository';
import { FileDB } from '../business_logic/models/fileDb';

const ROLE_RATE_BASE_URL = 'role-rate';
const NEW_ROLE_RATE_BASE_URL = 'api/v1/role-rate';

const useRolesData = () => {
  const {
    addRoleRate: addNewRoleRate,
    getRoles,
    updateRole,
  } = useRoleRatesRepository({ baseURL: Config.backendUrl });

  const { getRoleRatesHistory } = useRoleRateHistoryRepository({ baseURL: Config.backendUrl });
  const getRolesData = async (
    filters: RoleRateFilter = {},
    withStatistics: RoleRateStatistics = {},
  ) => {
    const roleRateList = await getRoles(`${ROLE_RATE_BASE_URL}/filter`, filters, withStatistics);
    const dataRatesInDollars = roleRateList.map((data: RoleRateItem) => {
      const newData = {
        ...data,
      };
      newData.rate = MoneyMethods.centsToDollars(Number(data.rate)).toString();
      return newData;
    });
    return dataRatesInDollars;
  };

  const getRoleHistoryData = async (filter: RoleRateHistoryFilter) => {
    const rolesHistoryDataList = await getRoleRatesHistory(`${ROLE_RATE_BASE_URL}-history/filter`, filter);
    const dataRatesHistoryInDollars = rolesHistoryDataList.map((data: RoleRateHistoryEntry) => {
      const newData: RoleRateHistoryEntry = {
        ...data,
      };
      newData.changes.rate[1] = parseFloat(MoneyMethods.centsToDollars(data.changes.rate[1]));
      return newData;
    });
    return dataRatesHistoryInDollars;
  };

  const addRoleRate = async ({
    standardRole,
    rate: newRate,
    typeOfRate,
    description: note,
  }: CreateNewRateForm) => {
    const newRateInCents = MoneyMethods.dollarsToCents(parseFloat(newRate));
    const newRoleRate = {
      role_rate: {
        standard_role: standardRole,
        rate: newRateInCents,
        type_of_rate: typeOfRate,
      },
      description: note,
    };
    return addNewRoleRate(newRoleRate, NEW_ROLE_RATE_BASE_URL);
  };

  const updateRoleRate = async ({
    id,
    description,
    files,
    rate = '',
  }: EditRateForm & { files?: FileDB | FileDB[] }) => {
    const roleData = {
      roleRate: {
        id,
        rate: rate ? MoneyMethods.dollarsToCents(Number(rate)) : 0,
      },
      files,
      description,
    };
    return updateRole(ROLE_RATE_BASE_URL, roleData);
  };

  const updateClientRoleRate = async (updateClientRoleRateReq: ClientRoleRateReq) =>
    updateRole(ROLE_RATE_BASE_URL, updateClientRoleRateReq);

  return {
    getRolesData,
    addRoleRate,
    getRoleHistoryData,
    updateRoleRate,
    updateClientRoleRate,
  };
};
export default useRolesData;
