import React from 'react';
import Typography from '@mui/material/Typography';

const pageTitleStyle = {
  fontWeight: 500,
  fontSize: '20px',
  color: 'primary.main',
  textAlign: 'center',
};

const pageSubTitleStyle = {
  fontWeight: 500,
  fontSize: '40px',
  color: 'black',
  textAlign: 'center',
};

type PageTitleProps = {
  title: string;
  subtitle?: string;
};

const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle }) => (
  <>
    <Typography variant='h4' sx={pageTitleStyle}>
      {title}
    </Typography>
    <Typography variant='h2' sx={pageSubTitleStyle}>
      {subtitle}
    </Typography>
  </>
);

PageTitle.defaultProps = {
  subtitle: '',
};
export default PageTitle;
