import React from 'react';

import {
  Stack, Typography, ListItemText, Tooltip,
} from '@mui/material';

const listItemStyle = {
  margin: '0',
  backgroundColor: 'rgb(239 235 235 / 50%)',
  padding: '5px 8px',
  borderRadius: '5px',
  height: '50px',
  maxWidth: '170px',
  boxShadow: '3px 3px 4px rgb(198 184 184 / 30%)',
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

type FileListProps = {
  files: File[];
};

const FileList: React.FC<FileListProps> = ({ files }) => {
  const filesRender = files.map((file) => {
    const fileSizeKb = (file.size / 1000).toFixed(1);
    return (
      <Tooltip key={file.name} title={file.name}>
        <ListItemText
          key={file.name}
          primary={file.name}
          secondary={`${fileSizeKb} kB`}
          sx={listItemStyle}
        />
      </Tooltip>
    );
  });

  return files.length ? (
    <Stack
      spacing={1}
      justifyContent='center'
      alignItems='flex-start'
      sx={{ width: '100%' }}
    >
      <Typography>Accepted files:</Typography>
      <Stack
        direction='row'
        spacing={2}
        justifyContent='center'
        alignItems='flex-start'
      >
        {filesRender}
      </Stack>
    </Stack>
  ) : null;
};

export default FileList;
