import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { GridEditCellProps } from '@mui/x-data-grid';
import useReportPreviewTables from '../../../../../hooks/useReportPreviewTables';

interface HolidaysDateColumnProps {
  params: GridEditCellProps;
  updateRowHeight: (newHeight: number) => void;
}

const HolidaysDateColumn: React.FC<HolidaysDateColumnProps> = ({
  params,
  updateRowHeight,
}: HolidaysDateColumnProps) => {
  const { value } = params;
  const { getRowHeight } = useReportPreviewTables();

  useEffect(() => {
    if (value.length > 1) {
      const initHeight = getRowHeight(value, value);
      updateRowHeight(initHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>{value.length > 1 ? value.map((dateValue: string) => <p>{dateValue}</p>) : value}</Stack>
  );
};

export default HolidaysDateColumn;
