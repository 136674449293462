import { useContext } from 'react';
import FeatureFlagContext from '../context/featureFlagContext';
import Config from '../utils/config';
import useFeatureFlagRepository from './useFeatureFlagRepository';

const FEATURE_FLAG_BASE_URL = 'api/v1/feature-flag';

const useFeatureFlag = () => {
  const { getFeatureFlagsFromBackend } = useFeatureFlagRepository({ baseURL: Config.backendUrl });

  const getFeatureFlags = async () => getFeatureFlagsFromBackend(FEATURE_FLAG_BASE_URL);

  /* eslint-disable */
  const getFeatureFlag = (toggleName: string) => {
    const { toggles } = useContext(FeatureFlagContext);
    return toggles[toggleName] || false;
  };

  /* eslint-disable */
  const getToggleFlag = (toggle: string, whenOn: any, whenOff?: any) => {
    const isBoolean = (value: any) => typeof value === 'boolean';
    if (isBoolean(whenOn) || isBoolean(whenOff)) {
      throw new Error(
        `whenOn and whenOff parameters can't be boolean.
        This is to avoid too many if statements in your code.`,
      );
    }
    const isOn = getFeatureFlag(toggle);

    if (isOn) {
      return whenOn;
    }
    return whenOff;
  };

  return {
    getToggleFlag,
    getFeatureFlags,
  };
};

export default useFeatureFlag;
