const MoneyMethods = {
  dollarsToCents(dollarAmount: number) {
    return Math.round(dollarAmount * 100);
  },

  centsToDollars(centsAmount: number) {
    return (centsAmount / 100).toFixed(2);
  },
};
export default MoneyMethods;
