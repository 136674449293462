import React from 'react';
import {
  InputLabel, MenuItem, FormControl, Select, SelectChangeEvent,
} from '@mui/material';
import { CreateNewRateForm, UseForm } from '../../../utils/types';

type ClientRoleSelectInputProps = {
  options: string[];
  setFormValue: UseForm<CreateNewRateForm>['setFormValues'];
};

const ClientRoleSelectInput: React.FC<ClientRoleSelectInputProps> = ({ options, setFormValue }) => {
  const [typeOfRate, setTypeOfRate] = React.useState(options[0]);
  const handleChange = (event: SelectChangeEvent) => {
    setFormValue((prevFormValues) => ({
      ...prevFormValues,
      typeOfRate: event.target.value,
    }));
    setTypeOfRate(event.target.value);
  };
  return (
    <FormControl variant='filled' sx={{ m: 1, width: '160px', minWidth: '120px' }}>
      <InputLabel id='demo-simple-select-filled-label'>Type of Rate</InputLabel>
      <Select
        labelId='demo-simple-select-filled-label'
        id='demo-simple-select-filled'
        value={typeOfRate}
        onChange={handleChange}
      >
        {options.map((rate) => (
          <MenuItem key={rate} value={rate}>
            {rate}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClientRoleSelectInput;
