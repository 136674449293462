import { AxiosRequestConfig } from 'axios';
import useAxiosInstance from './useAxiosInstance';

interface ReportRepositoryProps {
  baseURL: string;
}

const useReportRepository = ({ baseURL }: ReportRepositoryProps) => {
  const { axiosInstance } = useAxiosInstance({ baseURL });

  const getConfigToDownloadReport = (fileName: string) => {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Disposition': `attachment; filename=${fileName}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    };

    return axiosConfig;
  };

  const getReport = async (url: string, fileName: string) => {
    const config = getConfigToDownloadReport(fileName);
    const { data } = await axiosInstance.get(url, config);
    return data;
  };

  const getManualReport = async (url: string, fileName: string, files: FormData) => {
    const config = getConfigToDownloadReport(fileName);
    const { data } = await axiosInstance.post(url, files, config);
    return data;
  };

  return { getReport, getManualReport };
};

export default useReportRepository;
